import React from 'react'
import { useCurrentRefinements } from 'react-instantsearch'
import { useInstantSearch } from 'react-instantsearch-core'

import Close from '@mui/icons-material/Close'
import type { Theme } from '@mui/material'
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import type { SystemStyleObject } from '@mui/system'
import { bindToggle } from 'material-ui-popup-state'
import { usePopupState } from 'material-ui-popup-state/hooks'

import { ApplyFiltersButton } from '@/components/CategoriesPage/algolia/ApplyFiltersButton'
import MobileFacets from '@/components/CategoriesPage/MobileFacets'
import { HIERARCHICAL_CATEGORIES } from '@/constants/algolia'
import { useFilterSelectionContext } from '@/providers/c/FilterSelectionProvider'
import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints.ts'
import { useT } from '@/utils/frontend/useT'

import { CategoriesTree } from '../algolia/CategoriesTree'
import { ResetFilterButton } from '../algolia/ResetFiltersButton'
import { SortByDropdown } from '../algolia/SortBy'

const styles = {
  appBarHeader: {
    position: 'fixed',
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    bgcolor: 'background.default',
    color: 'text.primary',
    pr: 0,
    width: { xs: '100%', sm: '600px' },
  } as SystemStyleObject<Theme>,
  appBarFooter: {
    position: 'fixed',
    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
    bottom: 0,
    top: 'auto',
    bgcolor: 'background.default',
    width: { xs: '100%', sm: '600px' },
  } as SystemStyleObject<Theme>,
}

const DrawerContent = (props: { close: () => void }) => {
  const { close } = props
  const { selectedFilters } = useFilterSelectionContext()
  const { setIndexUiState } = useInstantSearch()
  const { trackFilterEvent } = useCategoriesAnalyticsTracking()

  const updateStateByClosingDrawer = () => {
    setIndexUiState((prevState) => {
      return {
        ...prevState,
        refinementList: {
          ...prevState.refinementList,
          ...Object.entries(selectedFilters).reduce((acc, [attribute, selectedItemValues]) => {
            acc[attribute] = selectedItemValues
            return acc
          }, {} as Record<string, string[]>),
        },
      }
    })
    close()
  }
  const updateStateAndTrack = () => {
    Object.entries(selectedFilters).forEach(([attribute, selectedItemValues]) => {
      trackFilterEvent(attribute, selectedItemValues)
    })
    updateStateByClosingDrawer()
  }

  const { t } = useT({ keyPrefix: 'catalog.filters' })

  return (
    <Box sx={{ width: { xs: '100vw', sm: '600px' } }}>
      {/* fixed header */}
      <AppBar component="div" elevation={0} sx={styles.appBarHeader}>
        <Toolbar>
          <Typography sx={{ flexGrow: 1, textAlign: 'center' }} variant="body1bold">
            {t('sidebar-filters-title')}
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              right: 16, // 24 side padding - 8 button padding
            }}
            aria-label="close filter sidebar"
            data-cy="close-filter-sidebar"
            onClick={updateStateByClosingDrawer}
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box>
        <Toolbar />
        <Container sx={{ pt: 1 }}>
          <Stack spacing={1} sx={{ pb: 4 }}>
            <Box sx={{ paddingTop: '24px' }}>
              <SortByDropdown dataCyPrefix="mobile" />
            </Box>
            <Divider sx={{ pt: 1 }} />
            <CategoriesTree dataCyPrefix="mobile" />
            <MobileFacets />
          </Stack>
        </Container>
        <Toolbar />
      </Box>
      {/* fixed footer */}
      <AppBar component="div" elevation={0} sx={styles.appBarFooter}>
        <Toolbar
          sx={{ justifyContent: 'space-between', display: 'flex', padding: '12px 16px' }}
        >
          <ResetFilterButton
            dataCy="sidebar-reset-all-filters"
            variant="outlined"
            startIcon={undefined}
            sx={{ flexGrow: 1, marginRight: '12px' }}
          />
          <ApplyFiltersButton
            onClick={updateStateAndTrack}
            sx={{ flexGrow: 1 }}
            dataCy="sidebar-apply-filters"
          />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export const SidebarFiltersDrawer = () => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'mobile-sidebar-filters' })
  const { t } = useT({ keyPrefix: 'catalog.filters' })
  const { items } = useCurrentRefinements({
    excludedAttributes: [HIERARCHICAL_CATEGORIES[0]],
  })
  const { isMobile } = useBreakpoints()

  const currentRefinementsCount = items
    .flat()
    .map((item) => item.refinements)
    .flat().length

  let buttonLabel = t('open-sidebar-filters')
  if (currentRefinementsCount !== 0) {
    buttonLabel += ` (${currentRefinementsCount})`
  }

  return (
    <>
      <Button
        {...bindToggle(popupState)}
        sx={{ width: { xs: '100%', md: 'initial' } }}
        variant="outlined"
        data-cy="open-filters-sidebar"
      >
        {buttonLabel}
      </Button>
      <Drawer open={popupState.isOpen} onClose={() => popupState.toggle()} anchor="right">
        <DrawerContent close={popupState.close} />
      </Drawer>
    </>
  )
}
