import { Box, Divider } from '@mui/material'

import { MultiSelectAccordionFilter } from '@/components/CategoriesPage/algolia/MultiSelectAccordionFilter'
import { usePrepareRefinementListFacets } from '@/components/CategoriesPage/usePrepareRefinementListFacets'

const MobileFacets = () => {
  const { facets, sortBy } = usePrepareRefinementListFacets()

  // TODO should be coming from the index configuration and not being hardcoded
  const maxFacetHits = 50

  return (
    <Box
      data-cy="filter-list"
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}
    >
      <Divider />
      {facets.map((attribute) => {
        const { attributeTechnicalName, title } = attribute

        return (
          <MultiSelectAccordionFilter
            key={attributeTechnicalName}
            title={title}
            attribute={attributeTechnicalName}
            limit={maxFacetHits}
            dataCy={attributeTechnicalName}
            sortBy={sortBy}
          />
        )
      })}
    </Box>
  )
}

export default MobileFacets
