import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import type { ButtonProps } from '@mui/material'
import { Button } from '@mui/material'

import { useT } from '@/utils/frontend/useT'

type ApplyFiltersButtonProps = {
  hide?: boolean
} & QaHook &
  ButtonProps

export const ApplyFiltersButton = (props: ApplyFiltersButtonProps) => {
  const { dataCy, ...buttonProps } = props

  const { t } = useT({ keyPrefix: 'catalog.filters' })

  return (
    <Button data-cy={`${dataCy}-button`} {...buttonProps}>
      {t('apply-filters')}
    </Button>
  )
}
